import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog37/image5.jpg"
import image2 from "../../images/blog37/image2.jpg"
import image3 from "../../images/blog37/image3.jpg"
import image4 from "../../images/blog37/image4.jpg"
import image6 from "../../images/blog37/image6.jpg"










export default function top3ReasonsWhyRehabFails(){
    return(
        <BlogPostOld
            src={image}
            title={"Top 3 Reasons Why Rehab Fails"}
            summary={"Not all physical therapy and/or chiropractic clinics are created equally. A large number of the people we help end up in our office having tried anything and everything for their issues. In fact this is the most common story we hear."}
            date={"July. 24, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <>
                <p>
                    Not all physical therapy and/or chiropractic clinics are created equally. A
                    large number of the people we help end up in our office having tried
                    anything and everything for their issues. In fact this is the most common
                    story we hear.
                </p>
                <p>
                    Feeling hopeless and as though no one really understands what they are going
                    through, someone has either insisted they come see us from personal
                    experience or stumbled across us and decided they would give it one last
                    shot before giving up.&nbsp;
                </p>
                <p>
                    This is a dangerous cross-road. One road leads to a tireless chase for pain
                    relief, trying any bio-hack or remedy that one can offer. The other leads to
                    avoiding any of the fun activities or social events. The things that used to
                    fire you up now have lost its spark and can even suck the life out of you.
                </p>
                <p>
                    The previous places you have been to were confident that it was this
                    diagnosis and that the solution was to fix this specific “problem” you are
                    having. You were given exercises, shots, surgeries for this diagnosis but
                    something still didn’t feel right. Though you told them this, you felt like
                    no one was listening.
                </p>
                <p>
                    I will say that rehab does work for a lot of people and the encouraging
                    thing for someone experiencing a new little ache or pain is that most
                    musculoskeletal conditions do regress to a mean even with time alone. This
                    normally happens in a reasonable timeline depending on a slew of factors,
                    but to be safe anywhere between 5 days and 12 weeks is a reasonable
                    timeline.
                </p>
                <p>
                    However, I must say that I do not think the current healthcare system is set
                    up to help people that require more help than time alone. Yes that sounds a
                    little harsh, but in my opinion the vast majority of success can be
                    attributed in large part to time. I think we need to be honest here and it
                    is something that I tell people all of the time.
                </p>
                <p>
                    If someone does need and/or desires more help, we are more than happy to
                    assist. This normally looks like a plateau they have hit during the recovery
                    process or they have been tossed around in the healthcare system, given
                    harmful advice without clear guidance and genuine support.
                </p>
                <p>
                    Anyway here are my top three reasons why the healthcare system fails someone
                    rehabbing an injury in no particular order.&nbsp;
                </p>
                <ol>
                    <li>- Unrealistic Expectations</li>
                    <li>- It was WAY too easy</li>
                    <li>- No one adjusted the game plan</li>
                </ol>
                <p>
                    Let’s start with number one: someone clearly gave you unrealistic
                    expectations. I think this is incredibly important. When we agree to help
                    someone, this is one of our top priorities. Recovery from an injury is NEVER
                    an easy process and is hardly ever a linear one. I was scrolling through my
                    social media the other day, and stumbled across this graphic that I think is
                    perfect for this scenario.
                </p>
                <img src={image3} />
                <p>
                    Too often are people told the story of how long it takes for certain body
                    parts to heal and that this process is straight forward. It is not. Life is
                    complex as is the recovery process. I think we also need to be more
                    realistic with how long this process takes as well.
                </p>
                <p>
                    Most of the time people come to us because this issue has placed limitations
                    on their life in some way, shape or form. We can say that in this state,
                    they are more fragile than they would like to feel due to the pain they are
                    experiencing. The problem with most rehab clinics is we give this
                    expectation that being pain free is the ultimate goal. I disagree.&nbsp;
                </p>
                <p>
                    Pain is something that is a part of the normal human experience. The goal is
                    to become resilient and to remove as many limitations as we can to live the
                    most fulfilling life we possibly can with the limited time we have on earth
                    with the people we love. Pain is something that can come and go much faster
                    than the time it takes to build resiliency.&nbsp;
                </p>
                <p>
                    This is often where I see people stop the process far too early and let
                    their guard down, just to be devastated when pain comes back. One of my
                    mentors once told me “the absence of symptoms does not mean the presence of
                    resilience”. This is where some people get discharged and are frustrated
                    when symptoms return. However, this is normal (and in my opinion almost
                    inevitable).&nbsp;
                </p>
                <p>
                    Being able to bounce back from a flare up and learn how to better manage
                    symptoms so things do not throw you off your game as much as it did before
                    is a mark of resilience. It takes mental fortitude to compartmentalize and
                    stay the course in the midst of a challenge like this.&nbsp;
                </p>
                <p>
                    Again, I think this comes back to laying down realistic expectations that we
                    are in this for the long game if we truly want to become a healthier and
                    more resilient version of ourselves.&nbsp;
                </p>
                <p>
                    The main take home from number 1: the road to recovery isn’t always easy but
                    you can’t go wrong with building resilience.
                </p>
                <p>
                    Number two: if it doesn’t challenge you, it doesn’t change you. Too often do
                    I hear about a rehab plan that is full of protocols, hand-outs or routines
                    that do not even come close to the activities of daily living this person
                    wants to have access to. No wonder the simple things are still giving them
                    issues.
                </p>
                <p>
                    Your rehab should be challenging. Your rehab should aim to provide you with
                    the resources (and then some) you require to do even the most strenuous
                    activities that you would like to do.&nbsp;
                </p>
                <p>
                    Think about it, if you want to be able to go back to playing golf but aren’t
                    incorporating power development (ave clubhead speed is 95 mph), aerobic
                    activity (ave course is 6 miles), general strength training, etc all of
                    which that exceeds what you will need on the course - are you really
                    preparing yourself for success? You can’t get the from floor exercises are a
                    generic hand-out that everyone is getting. It must cater to you, your
                    abilities/context and your goals.
                </p>
                <p>
                    This is the very basics. This should drive both tissue resilience as well as
                    confidence given the why behind that what is clearly explained for the
                    routine and gameplan. Every training session should feed into shaping the
                    body and mindset to become as resilient as possible.&nbsp;
                </p>
                <p>We must be like the world’s best athletes and train for the game so our body is prepared for the demands we place on it. This task doesn’t need to be an elite level sport - it can be climbing stairs, picking up the kids, playing catch with a friend.</p>
                <p>At the end of the day if we are not prepared for the activity we expose ourselves to, then risk goes up. We can learn this from some of the best sports medicine practitioners in the world. Like Tim Gabbet says “it is not the activity that injures us, it is the one we are not prepared for”.</p>
                <img src={image6}/>
                <p>
                    With this in mind, realize that building tissue takes time and building
                    fitness also takes time. To give an idea here is a graphic to show how long
                    it takes to build different components of the body. This stuff doesn’t
                    happen overnight.
                </p>
                <img src={image2} />
                <p>
                    So if you want to make physical changes, this takes time and that is okay.
                </p>
                <p>
                    Moral of the story for number two: Massages and ice alone won’t solve a
                    problem caused by a lack of activity.&nbsp;
                </p>
                <p>
                    Last but not least: no one took the time to make in game adjustments. This
                    one kills me because it doesn’t take much to just listen to the person in
                    front of you. This is why we focus on continuous feedback. We love getting
                    feedback and making sure we have a program dialed into where it needs to be.
                    This allows us to make sure the body is responding properly to the stressors
                    we are putting on it so the things that used to be hard won’t be so hard in
                    the future.
                </p>
                <p>
                    If this becomes the focus then we can narrow in on the things that we can do
                    to have a positive impact on building resiliency over a long period of time.
                    Pain is like the stock market, it can go up and down on any given day but it
                    is the trends over time that we should care about. We do not need a specific
                    diagnosis for this by the way, we just need to clarify what physical goals
                    you’d like to achieve and figure out a gameplan to get you there. THEN be
                    willing to adapt because plan A can’t work forever.&nbsp;
                </p>
                <p>
                    Surround yourself with people who are willing to stay in your corner and
                    help you stay the course. Support is very rare to experience when navigating
                    the healthcare system. It just isn’t set up for this. I love this post and
                    graphic from Brad Stulberg, the author of “Peak Performance”, “The Practice
                    of Groundness” and “Master of Change”.&nbsp;
                </p>
                <img src={image4} />
                <p>
                    Take home for number three: We need to be like the best teams in the world
                    and adapt on the fly when we need to and stick to the course when we need
                    to.&nbsp;
                </p>
                <p>
                    Receiving care that ensures you experience realistic expectations, rehab
                    that trains you for the game and relentless support willing to make
                    adjustments that work for you should be the bare minimum. This is how I
                    would treat my family (actually it is how I treat my family - I train my mom
                    weekly and we constantly make adjustments to better fit her goals and
                    preferences) and this is the standard I feel is necessary to help people
                    that really need it.
                </p>
            </>


        </BlogPostOld>

    )
}